import { ButtonLink } from 'driverama-core/components/button/Button'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { LINKS } from '../../../constants/links'
import { TESTING_IDS } from '../../../constants/testingIds'

export function LoginButton() {
  const { t } = useTranslation(['core'])
  const router = useRouter()

  return (
    <>
      <Link
        passHref
        href={addSearchParams(LINKS.sign, {
          redirect: true,
          next: router.asPath
        })}
      >
        <ButtonLink
          variant="secondary"
          data-testid={TESTING_IDS.signHeaderButton}
        >
          <span>{t('core:header_menu_sign_up_or_login')}</span>
        </ButtonLink>
      </Link>
      <Spacer size={6} />
    </>
  )
}
