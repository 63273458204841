import { css } from '@emotion/react'
import {
  ActiveLink,
  isExactAsPathLink
} from 'driverama-core/components/ActiveLink'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { ReactNode } from 'react'
import { useMedia } from 'react-use'
import { logGtmCtaClick } from 'utils/gtm/gtmEvents'
import { SContent, SLink } from './HeaderItem.styled'

type Props = {
  href: string
  label: string
  buttonName: string
  mobileLabel?: string
  icon?: ReactNode
  variant?: 'light' | 'dark'
}

export function HeaderItem(props: Props) {
  const { icon, label, mobileLabel, buttonName, variant } = props
  const onClick = () => {
    logGtmCtaClick(buttonName)
  }

  return (
    <ActiveLink
      href={props.href}
      passHref
      isActiveFn={isExactAsPathLink}
      scroll={true}
    >
      <SLink variant={variant} onClick={onClick}>
        <ItemContent icon={icon} label={label} mobileLabel={mobileLabel} />
      </SLink>
    </ActiveLink>
  )
}

function ItemContent(props: Pick<Props, 'icon' | 'label' | 'mobileLabel'>) {
  const { icon, label, mobileLabel } = props
  const isTablet = useMedia(media.lte('tablet'))

  return (
    <SContent>
      {icon}
      <TextBody
        size="small"
        variant="setup"
        css={css`
          @media ${media.lte('tablet')} {
            font-size: 11px;
            line-height: 16px;
          }
        `}
      >
        {isTablet && mobileLabel !== undefined ? mobileLabel : label}
      </TextBody>
    </SContent>
  )
}
