import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ButtonCircle,
  ButtonLink
} from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import { fadeIn } from 'driverama-core/styles/animations'
import { media } from 'driverama-core/styles/media'
import { size, spaceX } from 'driverama-core/styles/spacing'
import { color, radius, time } from 'driverama-core/styles/variables'
import { HeaderMenuVariant } from './HeaderMenu.utils'

export const SContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  ${spaceX(2)};

  @media ${media.gt('tablet')} {
    ${spaceX(4)};
  }

  > * {
    animation: ${fadeIn} ${time('control')} ease-in-out;
  }
`

export const SButtonCircle = styled(ButtonCircle)<{ isWhite?: boolean }>`
  ${({ isWhite }) =>
    isWhite &&
    css`
      color: ${color('white')};
      border-color: ${color('white', 0.4)};

      @media (hover: hover) {
        &:hover {
          color: ${color('white')};
          border-color: ${color('white')};
        }
      }
    `}
`

export const SButtonPhone = styled(ButtonLink)<{
  colorVariant: HeaderMenuVariant
}>`
  display: flex;
  gap: ${size(2)};

  height: ${size(11)};
  width: ${size(11)};

  ${({ colorVariant }) => {
    if (colorVariant === 'light') {
      return css`
        color: ${color('white')};
        border-color: ${color('white', 0.4)};
        svg {
          color: ${color('white')};
        }

        @media (hover: hover) {
          &:hover {
            border-color: ${color('white')};
            color: ${color('white')};
          }
        }
      `
    }

    return css`
      color: ${color('night-l-100')};
      border-color: ${color('night-l-650')};

      svg {
        color: ${color('night-l-100')};
      }

      @media (hover: hover) {
        &:hover {
          border-color: ${color('night-l-100')};
          color: ${color('night-l-100')};
        }
      }
    `
  }}

  padding: 0;

  border-radius: ${radius('full')};
  white-space: nowrap;

  @media ${media.gt('tablet')} {
    width: auto;
    padding: 0 ${size(4)};
  }
`

export const SButtonPhoneText = styled(TextBody)`
  display: none;

  @media ${media.gt('tablet')} {
    display: block;
  }
`
