import { LINKS } from 'constants/links'
import { isRegion } from 'driverama-core/utils/env'
import { TFunction } from 'i18next'
// import IconCirclePlus from 'images/icons/IconCirclePlus.svg'
import IconCheckSquare from 'images/icons/IconCheckSquare.svg'
import IconBell from 'images/icons/IconBell.svg'
import IconFeather from 'images/icons/IconFeather.svg'
import IconMessage from 'images/icons/IconMessage.svg'
import IconUser from 'images/icons/IconUser.svg'
// import IconHeart from 'images/icons/IconHeart.svg'
// import IconSliders from 'images/icons/IconSliders.svg'
// import IconInsurance from 'images/icons/profile/IconInsurence.svg'
import IconTag from 'images/icons/IconTag.svg'
import { FunctionComponent, SVGProps } from 'react'

interface ProfileLink {
  href: string
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  label: string
  buttonName?: string
}

export function profileLinks(t: TFunction) {
  const links: (ProfileLink | boolean)[] = [
    driverama.flags.IS_SELLING_ENABLED && {
      href: LINKS.profileOrders,
      icon: IconCheckSquare,
      label: t('core:header_menu_profile_orders'),
      buttonName: 'my_orders'
    },
    // {
    //   href: LINKS.profileInsurance,
    //   icon: IconInsurance,
    //   label: t('core:header_menu_profile_insurance')
    // },
    // {
    //   href: LINKS.profileNeeds,
    //   icon: IconCirclePlus,
    //   label: t('core:header_menu_profile_needs')
    // },
    {
      href: LINKS.profileSelling,
      icon: IconTag,
      label: t('core:header_menu_profile_selling')
    },
    // driverama.flags.IS_SELLING_ENABLED && {
    //   href: LINKS.profileSavedCars,
    //   icon: IconHeart,
    //   label: t('core:header_menu_profile_saved_cars')
    // },
    // driverama.flags.IS_SELLING_ENABLED && {
    //   href: LINKS.profileSavedSearches,
    //   icon: IconSliders,
    //   label: t('core:header_menu_profile_saved_searches')
    // },
    {
      href: LINKS.profilePersonalDetails,
      icon: IconUser,
      label: t('core:header_menu_profile_personal_details')
    },
    {
      href: LINKS.profileNotifications,
      icon: IconBell,
      label: t('core:header_menu_profile_notifications')
    }
  ]

  return links.filter((link): link is ProfileLink => !!link)
}

export function otherLinks(t: TFunction) {
  if (isRegion('de')) {
    return [
      {
        href: LINKS.contactUs,
        label: t('core:header_menu_profile_contact'),
        icon: IconMessage
      },
      {
        href: LINKS.blog,
        label: t('core:header_menu_profile_blog'),
        icon: IconFeather
      }
    ]
  }

  return [
    {
      href: LINKS.contactUs,
      label: t('core:header_menu_profile_contact'),
      icon: IconMessage
    }
  ]
}
