import styled from '@emotion/styled'

import { color } from 'driverama-core/styles/variables'
import NoImageIcon from 'driverama-core/images/NoImage.svg'

const SImagePlaceholder = styled.div`
  position: relative;
  padding-top: 75%;

  background: linear-gradient(
    300deg,
    ${color('almond-l-200')} 0%,
    ${color('almond-l-100')} 100%
  );

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export function ImagePlaceholder() {
  return (
    <SImagePlaceholder>
      <NoImageIcon color={color('almond-d-200')} />
    </SImagePlaceholder>
  )
}
