import styled from '@emotion/styled'
import { color } from 'driverama-core/styles/variables'

export const SContainer = styled.div`
  --max-container-width: 1920px;

  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  max-width: var(--max-container-width);
  margin: 0 auto;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
  clip: rect(0px, auto, auto, 0px);
`

export const SContent = styled.div<{ withGradient?: boolean }>`
  ${({ withGradient }) =>
    withGradient &&
    `
    background: ${color('white')};
    background: linear-gradient(
      180deg,
      ${color('night-l-800')} 0%,
      ${color('white')} 100%
    );
  `}
`

export const SFooter = styled.div`
  grid-row: 2 / 3;
`
