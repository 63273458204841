import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import {
  ButtonCircle,
  ButtonTransparent
} from 'driverama-core/components/button/Button'
import { css } from '@emotion/react'
import { color } from 'driverama-core/styles/variables'
import { TextBody } from 'driverama-core/components/text/Text'
import { CountryIcon } from 'driverama-core/components/countryIcon/CountryIcon'

// size(5) is new icon size, size(4) is default icon size
// we need to recalculate the size of padding
export const STrigger = styled(ButtonCircle)<{
  state?: 'open' | 'closed'
  isWhite?: boolean
}>`
  padding: calc(${size(3)} + (${size(4)} - ${size(5)}) / 2);

  ${({ state, isWhite }) =>
    state === 'open' &&
    css`
      color: ${color('white')} !important;
      border-color: ${color(isWhite ? 'white' : 'night-l-100')} !important;
      background-color: ${isWhite
        ? 'transparent'
        : color('night-l-100')} !important;
    `}

  ${({ isWhite }) =>
    isWhite &&
    css`
      border-color: ${color('white', 0.4)} !important;

      @media (hover: hover) {
        &:hover {
          border-color: ${color('white')} !important;
        }
      }
    `}
`

export const SItem = styled(ButtonTransparent)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  text-align: start;

  &:not(:last-of-type) {
    margin-bottom: ${size(4)};
  }
`

export const SIcon = styled(CountryIcon)`
  width: ${size(6)};
  height: ${size(6)};
`

export const SNavIcon = styled(CountryIcon)`
  width: ${size(5)};
  height: ${size(5)};
`

export const SCheck = styled.svg`
  width: ${size(6)};
  height: ${size(6)};

  margin-left: auto;

  color: ${color('night-l-100')};
`

export const SName = styled(TextBody)`
  color: ${color('black')};
`
