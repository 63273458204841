import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Logo from 'driverama-core/images/Logo.svg'
import { layoutGrid } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'
import { size, spaceX } from 'driverama-core/styles/spacing'
import { color, time, zIndex } from 'driverama-core/styles/variables'

export const SContainer = styled.header<{
  variant?: 'night' | 'white' | 'transparent' | 'night-dark'
  hasShadow?: boolean
  isAbsolute?: boolean
}>`
  ${layoutGrid};

  width: 100%;

  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}

  background: ${({ variant }) =>
    variant === 'night'
      ? color('night-l-800')
      : variant === 'white'
      ? color('white')
      : variant === 'night-dark'
      ? color('night-d-100')
      : 'transparent'};

  transition: background ${time('control')};

  @media ${media.lte('tablet')} {
    position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'relative')};
    z-index: ${({ variant }) =>
      zIndex(variant === 'white' ? 'top' : 'order-4')};
  }

  ${({ hasShadow }) =>
    hasShadow
      ? css`
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
          z-index: 2;
          position: fixed;
          width: 100%;
          right: 0;

          @media ${media.lte('tablet')} {
            box-shadow: none;
            position: initial;
            width: initial;
            right: initial;
          }
        `
      : null};
`

export const SContent = styled.div`
  display: flex;
  align-items: center;

  grid-column-start: col-1;
  grid-column-end: col-12;

  padding: ${size(6)} 0;

  position: relative;
  z-index: ${zIndex('order-1')};

  @media ${media.lte('tablet')} {
    padding: ${size(2)} 0;

    grid-column-end: col-4;
  }
`

export const SLogoLink = styled.a`
  display: flex;
  flex: 1;
  align-items: center;
`

export const SLogo = styled(Logo)`
  padding: 0;
  display: flex;
  @media ${media.lte('tablet')} {
    width: ${size(28)};
  }
`

export const SItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${spaceX(8)};

  @media ${media.lte('tablet')} and (orientation: portrait) {
    display: none;
  }

  @media ${media.lte('MD')} {
    padding-left: ${size(10)};
    padding-right: ${size(10)};
  }
`
