import { ActiveLink } from 'driverama-core/components/ActiveLink'
import { SItem } from './DropdownMenu.styled'
import { TextBody } from 'driverama-core/components/text/Text'
import { FC, SVGProps } from 'react'
import { logGtmCtaClick } from 'utils/gtm/gtmEvents'

interface Props {
  href: string
  text: string
  Icon: FC<SVGProps<SVGSVGElement>>
  name?: string
  onClick?: () => void
  isFeatured?: boolean
}
export function MenuLink({
  name,
  href,
  text,
  Icon,
  onClick,
  isFeatured
}: Props) {
  const handleOnClick = () => {
    name && logGtmCtaClick(name)
    onClick && onClick()
  }

  return (
    <ActiveLink href={href} passHref>
      <SItem onClick={handleOnClick} isFeatured={isFeatured}>
        <TextBody>{text}</TextBody>

        <Icon />
      </SItem>
    </ActiveLink>
  )
}
