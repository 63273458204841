import { FC, SVGProps } from 'react'
import { useTranslation } from 'react-i18next'
import { LINKS } from '../../../constants/links'
import IconBriefcase from '../../../images/icons/IconBriefcase.svg'
import IconClipboard from '../../../images/icons/IconClipboard.svg'
import IconMessageCircle from '../../../images/icons/IconMessageCircle.svg'
import IconUsers from '../../../images/icons/IconUsers.svg'
import IconShield from '../../../images/icons/sell/IconShield.svg'
import { MenuLink } from './MenuLink'

type Link = {
  name: string
  href: string
  text: string
  icon: FC<SVGProps<SVGSVGElement>>
}

interface Props {
  isFeatured?: boolean
}
export function CommonLinks({ isFeatured }: Props) {
  const { t } = useTranslation(['core'])

  const links: Link[] = [
    {
      name: 'about_us',
      href: LINKS.aboutUs,
      text: t('core:footer_menu_link_about_us'),
      icon: IconUsers
    },
    ...(!driverama.flags.IS_SELLING_SHUT_DOWN
      ? [
          {
            name: 'extended_warranties',
            href: LINKS.extendedWarranties,
            text: t('core:footer_menu_link_warranty'),
            icon: IconShield
          }
        ]
      : []),
    {
      name: 'careers',
      href: LINKS.careers,
      text: t('core:footer_menu_link_careers'),
      icon: IconBriefcase
    },
    {
      name: 'blog',
      href: LINKS.blog,
      text: t('core:footer_menu_link_blog'),
      icon: IconClipboard
    },
    {
      name: 'contact',
      href: LINKS.contactUs,
      text: t('core:footer_menu_link_contact'),
      icon: IconMessageCircle
    }
  ]

  return (
    <>
      {links.map(link => (
        <MenuLink
          key={link.href}
          name={link.name}
          href={link.href}
          text={link.text}
          Icon={link.icon}
          isFeatured={isFeatured}
        />
      ))}
    </>
  )
}
