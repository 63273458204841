import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size, spaceY } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

function isLight(variant: 'light' | 'dark') {
  return variant === 'light'
}

export const SLink = styled.a<{
  active?: boolean
  variant?: 'light' | 'dark'
}>`
  svg,
  ${TextBody} {
    transition: color 0.15s ease-in-out;
  }

  &:hover {
    svg,
    ${TextBody} {
      color: ${({ variant = 'dark' }) =>
        color(isLight(variant) ? 'white' : 'night-l-100')};
    }
  }

  ${({ variant = 'dark' }) =>
    isLight(variant) &&
    css`
      svg {
        color: ${color('white', 0.4)};
      }

      ${TextBody} {
        color: ${color('white')};
      }
    `}

  ${({ active, variant = 'dark' }) =>
    active &&
    css`
      svg,
      ${TextBody} {
        color: ${color(isLight(variant) ? 'white' : 'night-d-100')};
      }
    `}
    @media ${media.lte('tablet')} {
    ${({ active, variant = 'dark' }) =>
      active &&
      css`
        svg,
        ${TextBody} {
          color: ${color(
            isLight(variant) ? 'white' : 'night-d-100'
          )} !important;
        }
      `}
  }
`

export const SContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${size(20)};
  align-items: center;
  justify-content: center;

  color: ${color('night-l-200')};

  ${spaceY(1)};

  @media ${media.lte('tablet')} {
    min-width: ${size(14)};
  }
`
