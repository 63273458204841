import { css } from '@emotion/react'
import { TESTING_IDS } from 'constants/testingIds'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { Thumbnail } from 'driverama-core/components/thumbnail/Thumbnail'
import { size } from 'driverama-core/styles/spacing'
import { SDropdownCloseButton, SLoginButton } from './DropdownMenu.styled'
import IconClose from 'images/icons/IconClose.svg'
import IconAvatar from 'images/icons/IconUser.svg'

type Props = {
  isOpen: boolean
  isMobile: boolean
  isLoggedIn: boolean
  isWhite?: boolean
}

export function DropdownTriggerButton({
  isOpen,
  isMobile,
  isLoggedIn,
  isWhite
}: Props) {
  return (
    <>
      {isOpen && isMobile ? (
        <SDropdownCloseButton variant="outline">
          <IconClose />
        </SDropdownCloseButton>
      ) : isLoggedIn ? (
        <ButtonTransparent data-testid={TESTING_IDS.signHeaderButton}>
          <Thumbnail
            src="/images/placeholders/avatar.png"
            size="medium"
            variant="circular"
            css={css`
              width: ${size(11)};
              height: ${size(11)};
            `}
          />
        </ButtonTransparent>
      ) : (
        <SLoginButton
          variant="outline"
          isWhite={isWhite}
          data-testid={TESTING_IDS.signHeaderButton}
        >
          <IconAvatar />
        </SLoginButton>
      )}
    </>
  )
}
