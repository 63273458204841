import IconPhone from 'images/icons/IconPhone2.svg'
import { useContactPhone } from 'utils/useContactPhone'

import { SButtonPhone, SButtonPhoneText } from './HeaderMenu.styled'
import { HeaderMenuVariant } from './HeaderMenu.utils'

type Props = {
  variant?: HeaderMenuVariant
}

export function HeaderMenuContactPhone({ variant = 'dark' }: Props) {
  const { contactPhone, contactPhoneAsLink } = useContactPhone()

  if (!contactPhone) {
    return null
  }

  const isLightVariant = variant === 'light'

  return (
    <SButtonPhone
      variant="outline"
      href={contactPhoneAsLink}
      colorVariant={variant}
    >
      <IconPhone />
      <SButtonPhoneText
        variant="setup"
        size="small"
        color={isLightVariant ? 'white' : 'night-l-100'}
      >
        {contactPhone}
      </SButtonPhoneText>
    </SButtonPhone>
  )
}
