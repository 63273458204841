import { useTranslation } from 'react-i18next'
import { MenuLink } from './MenuLink'
import { profileLinks } from './DropdownMenu.utils'

export function ProfileLinks() {
  const { t } = useTranslation(['core'])

  return (
    <>
      {profileLinks(t).map(link => (
        <MenuLink
          key={link.href}
          href={link.href}
          text={link.label}
          Icon={link.icon}
          isFeatured
        />
      ))}
    </>
  )
}
