import { LINKS } from 'constants/links'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

export const useLogout = () => {
  const router = useRouter()
  const logout = useCallback(async () => {
    await router.push(LINKS.logout)
  }, [router])

  return { logout }
}
