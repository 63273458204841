import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TextBody } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, weight, time } from 'driverama-core/styles/variables'
import { Button } from 'driverama-core/components/button/Button'
import { media } from 'driverama-core/styles/media'

export const SItem = styled.a<{ active?: boolean; isFeatured?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: ${size(60)};
  width: 100%;

  :not(:last-child) {
    margin-bottom: ${size(6)};
  }

  color: ${color('night-text')};

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      font-weight: ${weight('bold')};
      color: ${color('night-d-200')};
    }
  }

  ${({ isFeatured }) =>
    isFeatured &&
    css`
      ${TextBody} {
        font-weight: ${weight('medium')};
        color: ${color('black')};
      }
    `}

  ${({ active }) =>
    active &&
    css`
      ${TextBody}, svg {
        font-weight: ${weight('bold')};
        color: ${color('night-d-200')};
      }
    `}
`

export const SIcon = styled.svg`
  color: inherit;
`

export const SLoginButton = styled(Button)<{ isWhite?: boolean }>`
  position: relative;

  width: ${size(11)};
  height: ${size(11)};

  svg {
    color: ${({ isWhite }) =>
      isWhite ? color('white') : color('night-l-650')};
    transition: ${time('control')} all;
  }

  padding: 0;
  border-radius: ${radius('full')};

  ${({ isWhite }) =>
    isWhite &&
    css`
      color: ${color('white')};
      border-color: ${color('white', 0.4)};

      @media (hover: hover) {
        &:hover {
          color: ${color('white')};
          border-color: ${color('white')};
        }
      }
    `}
`
export const SDropdownCloseButton = styled(Button)`
  display: flex;
  gap: ${size(2)};

  height: ${size(11)};
  width: ${size(11)};

  padding: 0;
  background-color: ${color('white')};

  color: ${color('black')};
  border-color: ${color('white', 0.4)};
  border-radius: ${radius('full')};

  svg {
    color: inherit;
  }

  @media (hover: hover) {
    &:hover {
      border-color: ${color('white')};
      color: ${color('white')};
    }
  }

  @media ${media.gt('tablet')} {
    width: auto;
    padding: 0 ${size(4)};
  }
`
