import { Divider } from 'driverama-core/components/divider/Divider'
import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { media } from 'driverama-core/styles/media'
import { useSession } from 'driverama-core/utils/auth'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useMedia } from 'react-use'
import { LINKS } from '../../../constants/links'
import { HeaderMenuVariant } from '../menu/HeaderMenu.utils'
import { CommonLinks } from './CommonLinks'
import { DropdownTriggerButton } from './DropdownTriggerButton'
import { LoginButton } from './LoginButton'
import { LogoutLink } from './LogoutLink'
import { ProfileLinks } from './ProfileLinks'

export function DropdownMenu({ variant }: { variant?: HeaderMenuVariant }) {
  const router = useRouter()
  const isMobile = useMedia(media.lte('mobile'))
  const { session } = useSession()

  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)

  const isLoggedIn = !!session?.access_token

  return (
    <Dropdown
      isFullHeight
      isMobileFull={isMobile}
      onClose={closeMenu}
      onOpen={openMenu}
      trigger={
        <DropdownTriggerButton
          isOpen={isOpen}
          isMobile={isMobile}
          isLoggedIn={isLoggedIn}
          isWhite={variant === 'light'}
        />
      }
    >
      {!isLoggedIn && router.route !== LINKS.sign && <LoginButton />}

      {isLoggedIn && (
        <>
          <ProfileLinks />
          <Divider />
          <Spacer axis="vertical" size={6} />
        </>
      )}

      <CommonLinks isFeatured={!isLoggedIn} />

      {isLoggedIn && (
        <>
          <Divider />
          <Spacer axis="vertical" size={6} />
          <LogoutLink />
        </>
      )}
    </Dropdown>
  )
}
