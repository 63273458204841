import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { useLocalisation } from 'driverama-core/utils/localisation'
import IconCheck from 'images/icons/IconCheck.svg'
import IconGlobe from 'images/icons/IconGlobe.svg'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useMedia } from 'react-use'
import { HeaderMenuVariant } from '../menu/HeaderMenu.utils'
import {
  SCheck,
  SIcon,
  SItem,
  SName,
  SNavIcon,
  STrigger
} from './HeaderLocalisation.styled'

export function HeaderLocalisation({
  variant = 'dark'
}: {
  variant?: HeaderMenuVariant
}) {
  const router = useRouter()
  const { t } = useTranslation(['core'])
  const [mode] = useState<'language' | 'region'>('language')
  const isMobile = useMedia(media.lte('mobile'))

  const queryClient = useQueryClient()
  const { activeLocale, locales, regions } = useLocalisation(t)

  return (
    <Dropdown
      isFullHeight
      isMobileFull={isMobile}
      trigger={
        <STrigger variant="outline" isWhite={variant === 'light'}>
          {<SNavIcon countryId={activeLocale?.icon} /> ?? <IconGlobe />}
        </STrigger>
      }
      // header={
      //   <Flex variant="row" justify="center">
      //     <Switch
      //       buttonStyles={css`
      //         @media ${media.lte('mobile')} {
      //           padding: ${size(2)} ${size(6)};
      //           padding-bottom: calc(${size(2)} - 2px);

      //           ${TextBody} {
      //             font-size: inherit;
      //           }
      //         }
      //       `}
      //       labels={{
      //         language: t('core:header_menu_language'),
      //         region: t('core:header_menu_region')
      //       }}
      //       value={mode}
      //       onChange={setMode}
      //     />
      //   </Flex>
      // }
    >
      {mode === 'language'
        ? locales.map(land => (
            <SItem
              key={land.lang}
              onClick={() => {
                queryClient.removeQueries()
                const { pathname, query } = router

                router.push({ pathname, query }, undefined, {
                  locale: land.value
                })
              }}
            >
              <SIcon countryId={land.icon} />

              <Spacer axis="horizontal" size={4} />

              <Flex variant="column">
                <SName>{land.lang}</SName>

                <TextBody size="small">{land.country}</TextBody>
              </Flex>

              <Spacer axis="horizontal" size={4} />

              {router.locale === land.value && <SCheck as={IconCheck} />}
            </SItem>
          ))
        : regions.map(region => (
            <SItem
              key={region.value}
              onClick={() => {
                if (process.env.NEXT_PUBLIC_REGION !== region.value) {
                  window.location.href = region.url
                }
              }}
            >
              <SIcon countryId={region.icon} />

              <Spacer axis="horizontal" size={4} />

              <Flex variant="column">
                <SName>{region.name}</SName>

                <TextBody size="small">
                  {region.value.toLocaleUpperCase()}
                </TextBody>
              </Flex>

              <Spacer axis="horizontal" size={4} />

              {process.env.NEXT_PUBLIC_REGION === region.value && (
                <SCheck as={IconCheck} />
              )}
            </SItem>
          ))}
    </Dropdown>
  )
}
