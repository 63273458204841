import { css } from '@emotion/react'
import {
  getResponsiveStyles,
  ResponsiveValue
} from 'driverama-core/styles/responsivity'
import { size } from 'driverama-core/styles/spacing'
import { radius } from 'driverama-core/styles/variables'
import { ImagePlaceholder } from 'driverama-core/components/imagePlaceholder/ImagePlaceholder'

type Props = {
  src?: string
  className?: string
  size?: ResponsiveValue<'small' | 'medium' | 'large'>
  variant?: ResponsiveValue<
    'circular' | 'corner-smaller' | 'corner' | 'corner-medium'
  >
}

export function Thumbnail(props: Props) {
  const { src, className, size: sizeFromProps, variant } = props

  if (!src) {
    return <ImagePlaceholder />
  }

  return (
    <img
      src={src}
      className={className}
      css={css`
        object-fit: cover;
        flex-shrink: 0;

        ${getResponsiveStyles(sizeFromProps ?? 'medium', {
          large: {
            width: size(14),
            height: size(14)
          },
          medium: {
            width: size(12),
            height: size(12)
          },
          small: {
            width: size(10),
            height: size(10)
          }
        })};

        ${getResponsiveStyles(variant ?? 'corner-smaller', {
          corner: {
            borderRadius: radius('corner')
          },
          'corner-smaller': {
            borderRadius: radius('corner-smaller')
          },
          'corner-medium': {
            borderRadius: radius('corner-medium')
          },
          circular: {
            borderRadius: radius('full')
          }
        })};
      `}
    />
  )
}
