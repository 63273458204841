import { css } from '@emotion/react'
import { LINKS } from 'constants/links'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, definitions } from 'driverama-core/styles/variables'
import IconFinance from 'images/icons/menu/IconFinance.svg'
import IconGarage from 'images/icons/menu/IconGarage.svg'
import IconSearch from 'images/icons/menu/IconSearch.svg'
import IconSell from 'images/icons/menu/IconSell.svg'
import IconTradeIn from 'images/icons/menu/IconTrade.svg'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { HeaderItem } from 'sections/header/HeaderItem'
import { logGtmCtaClick } from 'utils/gtm/gtmEvents'
import { SContainer, SContent, SItemsWrapper, SLogo } from './Header.styled'
import { getHeaderItemVariant, HeaderContainerVariant } from './Header.utils'
import { HeaderMenu } from './menu/HeaderMenu'

export function Header({
  variant,
  hasShadow,
  isHomepage,
  logoColor,
  className,
  menu = <HeaderMenu />
}: {
  variant?: HeaderContainerVariant
  hasShadow?: boolean
  isHomepage?: boolean
  menu?: JSX.Element
  logoColor?: keyof typeof definitions['c']
  className?: string
}) {
  const { t } = useTranslation(['core'])

  const headerItemVariant = getHeaderItemVariant(variant, isHomepage)
  const isSellingShutDown = driverama.flags.IS_SELLING_SHUT_DOWN
  const isSearchCarsShutDown = driverama.flags.IS_SEARCH_CARS_SHUT_DOWN

  return (
    <SContainer
      variant={variant}
      hasShadow={hasShadow}
      isAbsolute={isHomepage}
      className={className}
    >
      <SContent>
        <Link href={LINKS.home} passHref>
          <a css={{ flex: 1 }} onClick={() => logGtmCtaClick('driverama_logo')}>
            <SLogo
              css={css`
                color: ${color(
                  logoColor ||
                    (variant === 'night-dark' ? 'night-l-800' : 'night')
                )};

                @media ${media.lte('tablet')} {
                  width: ${size(28)};
                }
              `}
            />
          </a>
        </Link>

        <SItemsWrapper>
          <HeaderItem
            variant={headerItemVariant}
            icon={<IconSell />}
            label={t('core:header_menu_link_sell_cars')}
            href={
              driverama.flags.IS_SELLING_ENABLED && !isSellingShutDown
                ? LINKS.homeSelling
                : LINKS.home
            }
            buttonName="sell"
          />

          {driverama.flags.IS_SELLING_ENABLED && !isSearchCarsShutDown && (
            <HeaderItem
              variant={headerItemVariant}
              icon={<IconSearch />}
              label={t('core:header_menu_link_search_cars')}
              href={LINKS.carsSearch}
              buttonName="search_cars"
            />
          )}

          {!isSellingShutDown && (
            <HeaderItem
              variant={headerItemVariant}
              icon={<IconTradeIn />}
              label={t('core:header_menu_link_trade_in')}
              href={LINKS.tradeIn}
              buttonName="trade_in"
            />
          )}

          {driverama.flags.IS_SELLING_ENABLED && !isSellingShutDown && (
            <HeaderItem
              variant={headerItemVariant}
              icon={<IconFinance />}
              label={t('core:header_menu_link_finance')}
              href={LINKS.financing}
              buttonName="finance"
            />
          )}

          {driverama.flags.IS_B2B_ENABLED && (
            <HeaderItem
              variant={headerItemVariant}
              icon={<IconGarage />}
              label={t('core:header_menu_link_b2b')}
              href={LINKS.b2bSell}
              buttonName="b2b"
            />
          )}
        </SItemsWrapper>

        {menu}
      </SContent>
    </SContainer>
  )
}
