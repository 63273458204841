import { useTranslation } from 'react-i18next'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { SItem } from './DropdownMenu.styled'
import { TextBody } from 'driverama-core/components/text/Text'
import IconLogout from '../../../images/icons/IconLogOut.svg'
import { useLogout } from '../../../utils/useLogout'

export function LogoutLink() {
  const { t } = useTranslation(['core'])
  const { logout } = useLogout()

  return (
    <ButtonTransparent css={{ width: '100%' }} onClick={logout}>
      <SItem as="span">
        <TextBody css={{ color: 'inherit' }}>
          {t('core:header_menu_profile_logout')}
        </TextBody>

        <IconLogout />
      </SItem>
    </ButtonTransparent>
  )
}
