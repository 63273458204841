import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { useSession } from 'driverama-core/utils/auth'
import { DropdownMenu } from 'sections/header/dropdownMenu/DropdownMenu'
import { HeaderLocalisation } from 'sections/header/headerLocalisation/HeaderLocalisation'
import { SContainer } from './HeaderMenu.styled'
import { HeaderMenuContactPhone } from './HeaderMenuContactPhone'

export function HeaderMenu({
  variant,
  withContactPhone
}: {
  variant?: 'light' | 'dark'
  withContactPhone?: boolean
}) {
  const { loading } = useSession()

  return (
    <SContainer>
      {withContactPhone && <HeaderMenuContactPhone variant={variant} />}

      <HeaderLocalisation variant={variant} />

      {loading ? <Spacer size={11} /> : <DropdownMenu variant={variant} />}
    </SContainer>
  )
}
