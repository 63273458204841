export type HeaderContainerVariant =
  | 'night'
  | 'white'
  | 'transparent'
  | 'night-dark'

export function getHeaderItemVariant(
  headerContainerVariant?: HeaderContainerVariant,
  isHomepage?: boolean
) {
  if (isHomepage) {
    return 'light'
  }

  if (headerContainerVariant === 'night-dark') {
    return 'light'
  }

  if (
    headerContainerVariant === 'night' ||
    headerContainerVariant === 'white' ||
    headerContainerVariant === 'transparent'
  ) {
    return 'dark'
  }

  return 'dark'
}
